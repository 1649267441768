import React from "react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, Dropdown, DropdownMenu, DropdownTrigger, DropdownItem, Image, Avatar } from "@nextui-org/react";
import { router } from "@inertiajs/react";
import { AdjustmentsHorizontalIcon, ArrowLeftStartOnRectangleIcon, AtSymbolIcon, Bars4Icon, BoldIcon, BoltIcon, BuildingLibraryIcon, ChatBubbleLeftEllipsisIcon, ChatBubbleLeftRightIcon, ChevronDoubleDownIcon, ChevronDownIcon, DocumentCurrencyDollarIcon, EnvelopeIcon, FlagIcon, NewspaperIcon, QuestionMarkCircleIcon, QueueListIcon, UserCircleIcon, UserGroupIcon, UsersIcon } from "@heroicons/react/24/outline";

declare global {
    interface Window {
        Chatra: any;
    }
}

const Footer: React.FC = () => {
    const openChatra = () => {
        if (window.Chatra) {
            window.Chatra('openChat', true);
        } else {
            console.error('Chatra is not loaded yet.');
        }
    };
    return (
        <footer className="p-4 sm:p-6 bg-zinc-900">
            <div className="mx-auto max-w-screen-xl">
                <div className="md:flex flex-col lg:flex-row justify-center md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <a href="/" className="flex items-center">
                            <Image src="/assets/img/logo_ph.png" className="mr-3 h-24" alt="FlowBite Logo" />
                        </a>
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">

                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Важные</h2>
                            <ul className="text-gray-600 dark:text-gray-400">
                                <li className="mb-4">
                                    <a href="/" className="hover:underline ">Обмен</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/about" className="hover:underline">О нас</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://discord.gg/4eeurUVvTy" className="hover:underline">Отзывы</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://discord.gg/4eeurUVvTy" className="hover:underline">Резервы</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Коммуникации</h2>
                            <ul className="text-gray-600 dark:text-gray-400">
                                <li className="mb-4">
                                    <a href="https://phygicoin.gitbook.io/phygicoin-exchange" className="hover:underline">Документация</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/faq" className="hover:underline">FAQ</a>
                                </li>
                                <li className="mb-4">
                                    <a onClick={openChatra} className="hover:underline">Поддержка</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="/" className="hover:underline">PhygiCoin™</a>. All Rights Reserved.
                    </span>

                </div>
            </div>
        </footer>
    );
};

export default Footer;
